html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

button:disabled {
  opacity: 0.5;
}

.article-body {
  font-size: 1.4em;
  text-align: center;
  float: none;
}

.article-hdr {
  margin-bottom: 15px;
  border-bottom: 4px double #DDD;
  background-color: grey;
  display: flex;
  align-items: flex-end;
}

.article-meta {
  margin-bottom: 5px;
  margin-left: 10px;
}

.article-title {
  font-size: 2.5em;
}

.article-wrapper {
  display: inline-block;
  width: 100%;
}

.article-image-author {
  float:left;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  shape-outside: circle();
  margin: 10px;
}

.article-image-left {
  float:right;
  width: 50%;
  margin-left: 10px;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  shape-outside: ellipse();
}

.article-image-right {
  position: relative;
  clear: right;
  float:right;
  width: 50%;
  margin-left: 20px;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  shape-outside: ellipse();
}

.custom-header {
  border-bottom: 1px solid #777 !important;
}

.custom-carousel {
  height: 50vh;
  border-bottom: 1px solid #DDD;
  box-shadow: 0 10px 20px 0px #DDD;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: hidden;
}

.carousel-image-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.carousel-image {
  width:100%;
  max-height: 90vh;
}

.carousel-caption {
  position: fixed;
  top: 30vh;
}

/* ENTRY */

.entry-border {
  padding: 30px;
  background-color: #FFF;
  border: 4px double #DDD;
}

.entry-hdr {
  margin-bottom: 15px;
  border-bottom: 4px double #DDD;
}

.entry-meta {
  color: #CFCFCF;
  font-size: 13px;
}

.entry-image-mobile {
  width: 100%;
  height: auto;
}

.entry-title, .entry-meta {
  margin: 0 0 10px 0;
}

.inner-entry {
  padding: 10px;
}

/* MOBILE */
@media only screen
  and (min-device-width: 320px)
  and (max-device-width: 767px) {
    .no-show-mobile {
      display: none;
    }

    .media-left {
      padding-right: 0px;
    }
}

@media only screen
  and (min-device-width: 768px) {
    .no-show-desktop {
      display: none;
    }
  }

/* HELPERS */

.display-inline {
  display: inline !important;
}

.f-white {
  color: white;
}

.h-85 {
  height: 85px;
}

.lh-4-dot-5 {
  line-height: 4.5;
}

.m-b-0 {
  margin-bottom: 0px !important;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.no-border-radius {
  border-radius: 0px !important;
}

.padding-0 {
  padding: 0 !important;
}

.padding-5 {
  padding: 5px;
}

.width-100 {
  width: 100%;
}
